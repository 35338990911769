import { modelConvertToNum, modelConvertToStr } from "../constants/constants";

/**
 * The GoogleSettings model module.
 * @module model/GoogleSettings
 * @version 1.0.0
 */
class GoogleSettings {
  /**
   * Constructs a new <code>GoogleSettings</code>.
   * @alias module:model/GoogleSettings
   */
  constructor() {
    /**
     * @member {string} serviceAccountData
     * @default ''
     */
    this.serviceAccountData = '';

    /**
     * @member {string} directory
     * @default ''
     */
    this.directory = '/';
  }

  /**
   * Constructs a <code>GoogleSettings</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/GoogleSettings} obj Optional instance to populate.
   * @return {GoogleSettings} The populated <code>GoogleSettings</code> instance.
   */
  static constructFromObject(data, obj = null) {
    obj = obj || new GoogleSettings();

    if (data) {
      if (data.hasOwnProperty('serviceAccountData')) {
        obj.serviceAccountData = modelConvertToStr(data.serviceAccountData);
      }
      if (data.hasOwnProperty('directory')) {
        obj.directory = modelConvertToStr(data.directory);
      }
    }

    return obj;
  }
}

export default GoogleSettings;
