import _ from "lodash";

/**
 * @enum {string}
 */
export const CloneMode = {
  copy: 'copy',
};

export const CloneModeSet = new Set(Object.values(CloneMode));

/**
 * @enum {string}
 */
export const FtpBackend = {
  curl: 'curl',
  wget: 'wget',
  native: 'native',
  s3: 's3',
  ftpShare: 'ftpShare',
  googleDrive: 'googleDrive',
};

export const FtpBackendSet = new Set(Object.values(FtpBackend));

export const modelConvertToBool = (data) => {
  return data === 1 || data === '1' || data === true || data === 'true';
};

export const modelConvertToStr = (data) => {
  return !data ? '' : `${data}`;
};

export const modelConvertToNum = (data) => {
  const number = Number(data);
  return isNaN(number) ? 0 : number;
};

export const FileExtensionType = {
  subtitles: 'subtitles',
  audio: 'audio',
  video: 'video',
  all: 'all',
};

export const FileExtensionTypeSet = new Set(Object.values(FileExtensionType));
export const FileExtensionTypeProps = Object.values(FileExtensionType).map(name => ({
  label: _.capitalize(name),
  value: name,
}))
