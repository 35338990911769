import React, { useState } from 'react';
import {Button, Form, Input, InputNumber, Select, Switch, Upload} from "antd";
import Title from "antd/es/typography/Title";
import {MinusCircleOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import ActionConfig from "../../models/action-config";
import { FileExtensionTypeProps } from "../../constants/constants";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ftpConfig = (name, isDestination) => {
  return <>
    <Form.Item name={[name, 'host']} label="Host" rules={[{ min: 1, required: true }]} >
      <Input />
    </Form.Item>
    <Form.Item name={[name, 'port']} label="Port" rules={[{ required: true }]} >
      <InputNumber />
    </Form.Item>
    <Form.Item name={[name, 'username']} label="Username" >
      <Input />
    </Form.Item>
    <Form.Item name={[name, 'password']} label="Password" >
      <Input.Password />
    </Form.Item>
    <Form.Item name={[name, 'path']} label="Source path" >
      <Input />
    </Form.Item>
    <Form.Item valuePropName="checked" name={[name, 'secure']} label="Use secure connection" >
      <Switch />
    </Form.Item>
    <Form.Item valuePropName="checked" name={[name, 'sftp']} label="Use SFTP connection" >
      <Switch />
    </Form.Item>
    {isDestination && <Form.Item valuePropName="checked" name={[name, 'deleteUnusedFiles']} label="Delete unused files" >
      <Switch />
    </Form.Item>}
  </>
}

const GoogleDriveConfig = ({name}) => {
  const form = Form.useFormInstance();
  const [fileName, setFileName] = useState('')


  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      try {
        JSON.parse(reader.result);

        console.log([name, 'googleDrive', 'serviceAccountData']);
        form.setFieldValue( [name, 'googleDrive', 'serviceAccountData'], reader.result);
        setFileName(file.name);
      } catch (error) {
        setFileName('');
      }
    };
    reader.readAsText(file);
    return false;
  };

  return <>
    <>
      <Form.Item name={[name, 'googleDrive', 'serviceAccountData']} label="Service Account Key" >
        <TextArea />
      </Form.Item>
      <Form.Item label="Upload" >
        <Upload
            customRequest={({ file }) => handleFileUpload(file)}
            showUploadList={false}
        >
          <Button icon={<UploadOutlined />}>{fileName ? `${fileName}` : 'Upload Service Account Key file'}</Button>
        </Upload>
      </Form.Item>

      <Form.Item name={[name, 'googleDrive', 'directory']} label="Directory" rules={[{ required: true }]} >
        <Input />
      </Form.Item>
    </>
  </>
}

const s3Config = (name) => {
  return <>
    <Form.Item name={[name, 'sourceS3', 'host']} label="Host" rules={[{ min: 1, required: true }]} >
      <Input />
    </Form.Item>
    <Form.Item name={[name, 'sourceS3', 'port']} label="Port" rules={[{ required: true }]} >
      <InputNumber />
    </Form.Item>
    <Form.Item name={[name, 'sourceS3', 'access_key']} label="Access key" >
      <Input />
    </Form.Item>
    <Form.Item name={[name, 'sourceS3', 'secret_key']} label="Secret key" >
      <Input.Password />
    </Form.Item>
    <Form.Item name={[name, 'sourceS3', 'region']} label="Region" >
      <Input />
    </Form.Item>
    <Form.Item name={[name, 'sourceS3', 'path']} label="Path" >
      <Input />
    </Form.Item>
  </>
}


const RenderFtpDetails = ({ data, name, isDestination }) => {
  const renderForm = (backend) => {
    const isGoogle = backend === 'googleDrive';
    return <>
      {isGoogle && <Title level={5}> - Google Drive Config</Title> && <GoogleDriveConfig name={name} />}
      {!isGoogle && backend !== 's3' && <Title level={5}> - FTP Config</Title> && ftpConfig(name, isDestination)}
      {!isGoogle && backend === 's3' && <Title level={5}> - S3 Config</Title> && s3Config(name, isDestination)}
      {printStatus(data?.status)}
    </>
  }

  const [backend, setBackend] = useState(isDestination ? data?.uploadBackend : data?.downloadBackend)

  return <>
    {!isDestination && <Form.Item name={[name, 'downloadBackend']} label="Download backend">
      <Select
        onChange={setBackend}
        style={{ width: 130 }}
        options={[
          { value: 'wget', label: 'wget' },
          { value: 'curl', label: 'curl' },
          { value: 's3', label: 's3' },
          { value: 'ftpShare', label: 'FTP Share' },
          { value: 'googleDrive', label: 'Google Drive' },
        ]} />
    </Form.Item>}
    {isDestination && <Form.Item name={[name, 'uploadBackend']} label="Upload backend">
      <Select
        onChange={setBackend}
        style={{ width: 120 }}
        options={[
          { value: 'curl', label: 'curl' },
          { value: 's3', label: 's3' },
        ]} />
    </Form.Item>}
    {renderForm(backend)}
    {isDestination && <Form.Item name={[name, 'keepFilesDays']} label="Keep files of (days)" >
      <Select
        style={{ width: 120 }}
        options={
          [...new Array(31)].map((_, index) => {
            return {
              label: `${index + 1}`,
              value: `${index + 1}`,
            }
          })
        } />
    </Form.Item>}
  </>
}

const printStatus = (status) => {
  const isValidStatus = Boolean(status?.statusBool)
  const message = status?.message || 'Undefined'
  const lastUpdate = moment(status?.lastUpdate).format('YYYY-MM-DD HH:mm:ss')


  return <>
    <Form.Item label="Status">
        { isValidStatus && <span>Connected</span> }
        { !isValidStatus && <span>{ message }</span> }
    </Form.Item>
    <Form.Item label="Last update">
      {lastUpdate}
    </Form.Item>
  </>

};

export const Action = ({ data, onUpdate, isCreate }) => {
  const [initialValue] = useState(new ActionConfig());
  return <Form
      { ...layout }
      name="nest-messages"
      onFinish={onUpdate}
      style={{ maxWidth: 600 }}
      initialValues={isCreate ? initialValue : data}
    >
      <Form.Item name={['mode']} label="Clone mode">
        <Select
          style={{ width: 120 }}
          options={[
            { value: 'copy', label: 'Copy' },
          ]} />
      </Form.Item>
      <Form.Item name={['extensionType']} label="Media type">
        <Select
          style={{ width: 120 }}
          options={FileExtensionTypeProps} />
      </Form.Item>
      <Form.Item name={['apiKey']} label="API key for channel" >
        <Input />
      </Form.Item>
      <Form.Item name={['channel']} label="Channel short name" rules={[{ min: 4, max: 8 }]} >
        <Input />
      </Form.Item>
      <Form.Item name={['interval']} label="Update interval">
        <Select
          style={{ width: 120 }}
          options={[
            { value: '5m', label: '5 minutes' },
            { value: '10m', label: '10 minutes' },
            { value: '15m', label: '15 minutes' },
            { value: '20m', label: '20 minutes' },
            { value: '30m', label: '30 minutes' },
          ]} />
      </Form.Item>
      {printStatus(data?.apiUrlStatus)}
      <Title level={5}>Source</Title>
      <RenderFtpDetails
        data={data.source}
        name={'source'}
        isDestination={false}
      />
      <Title level={5}>Destination</Title>
      <Form.List name="destinations">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <>
                <Form.Item
                  {...restField}
                  name={[name, 'channelName']}
                  rules={[{ required: true }]}
                  label={'Channel name'}
                >
                  <Input />
                </Form.Item>
                <RenderFtpDetails
                  data={data.destinations[name]}
                  name={name}
                  isDestination={true}
                />
                <Form.Item>
                  <Button type="dashed" onClick={() => remove(name)} block icon={<MinusCircleOutlined />}>
                    Remove
                  </Button>
                </Form.Item>
              </>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add(initialValue.destinations[0])} block icon={<PlusOutlined />}>
                Add
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
        <Button type="primary" htmlType="submit">
          {!isCreate ? 'Update' : 'Create'}
        </Button>
      </Form.Item>
    </Form>
}
