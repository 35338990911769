import {
  FtpBackend,
  FtpBackendSet,
  modelConvertToBool,
  modelConvertToNum,
  modelConvertToStr
} from "../constants/constants";
import S3Settings from "./s3-settings";
import _ from "lodash";
import GoogleSettings from "./google-settings";

/**
 * The FtpSettings model module.
 * @module model/FtpSettings
 * @version 1.0.0
 */
class FtpSettings {
  /**
   * Constructs a new <code>ConfigEmail</code>.
   * @alias module:model/ConfigEmail
   */
  constructor() {
    /**
     * @member {string} host
     * @default '127.0.0.1'
     */
    this.host = '127.0.0.1';

    /**
     * @member {number} port
     * @default 21
     */
    this.port = 21;

    /**
     * @member {string} username
     * @default ''
     */
    this.username = '';

    /**
     * @member {string} password
     * @default ''
     */
    this.password = '';

    /**
     * @member {string} path
     * @default '/'
     */
    this.path = '/';

    /**
     * @member {boolean} sftp
     * @default false
     */
    this.sftp = false;

    /**
     * @member {boolean} secure
     * @default false
     */
    this.secure = false;

    /**
     * @member {FtpBackend} downloadBackend
     * @default 'wget'
     */
    this.downloadBackend = FtpBackend.wget;

    /**
     * @member {FtpBackend} uploadBackend
     * @default 'curl'
     */
    this.uploadBackend = FtpBackend.curl;

    /**
     * @member {S3Settings} sourceS3
     */
    this.sourceS3 = new S3Settings();

    /**
     * @member {GoogleSettings} googleDrive
     */
    this.googleDrive = new GoogleSettings();

    this.status = {};

    FtpSettings.readOnlyFields = ['status'];
  }

  /**
   * Constructs a <code>ConfigEmail</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/FtpSettings} obj Optional instance to populate.
   * @param isUpdate
   * @return {FtpSettings} The populated <code>FtpSettings</code> instance.
   */
  static constructFromObject(data, obj = null, isUpdate = false) {
    obj = obj || new FtpSettings();

    if (data) {
      if (data.hasOwnProperty('port') && !isNaN(Number(data.port))) {
        obj.port = modelConvertToNum(data.port);
      }

      if (data.hasOwnProperty('status')) {
        obj.status = _.cloneDeep(data.status);
      }

      if (data.hasOwnProperty('host')) {
        obj.host = modelConvertToStr(data.host);
      }

      if (data.hasOwnProperty('username')) {
        obj.username = modelConvertToStr(data.username);
      }

      if (data.hasOwnProperty('password')) {
        obj.password = modelConvertToStr(data.password);
      }

      if (data.hasOwnProperty('path')) {
        obj.path = modelConvertToStr(data.path);
      }

      if (data.hasOwnProperty('sftp')) {
        obj.sftp = modelConvertToBool(data.sftp);
      }

      if (data.hasOwnProperty('secure')) {
        obj.secure = modelConvertToBool(data.secure);
      }

      if (data.hasOwnProperty('downloadBackend') && FtpBackendSet.has(data.downloadBackend)) {
        obj.downloadBackend = modelConvertToStr(data.downloadBackend);
      }

      if (data.hasOwnProperty('uploadBackend') && FtpBackendSet.has(data.uploadBackend)) {
        obj.uploadBackend = modelConvertToStr(data.uploadBackend);
      }

      if (data.hasOwnProperty('sourceS3')) {
        obj.sourceS3 = S3Settings.constructFromObject(data.sourceS3);
      }

      if (data.hasOwnProperty('googleDrive')) {
        obj.googleDrive = GoogleSettings.constructFromObject(data.googleDrive);
      }
    }

    if (isUpdate) {
      return _.omit(obj, FtpSettings.readOnlyFields || [])
    }

    return obj;
  }
}

export default FtpSettings;
